input {
    font-size: 4rem;
    cursor: pointer;
    height: 2em;
    width: 2em;
    text-align: center;
}

@media (max-width: 570px) and (min-width: 401px) {
    input {
        font-size: 3rem;
        height: 1.25em;
        width: 1.25em;
    }
}

@media (max-width: 400px) {
    input {
        font-size: 2.5rem;
        height: 1em;
        width: 1em;
    }
}