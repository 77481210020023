button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1em;
    border-radius: .2em;
    background-color: white;
    border: none;
    color: #c3c7c9;
    cursor: pointer;
}