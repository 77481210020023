.victory-wrap {
    position: fixed;
    background-color: #c3c7c9;
    color: white;
    width: 28em;
    height: 7em;
    top: 7%;
    left: 50%;
    margin-top: -1em; /* Negative half of height. */
    margin-left: -14em;
    border-radius: .2em;
}

@media (max-width: 570px) and (min-width: 401px) {
    .victory-wrap {
        width: 20em;
        margin-left: -10em;
    }
}

@media (max-width: 400px) {
    .victory-wrap {
        width: 18em;
        margin-left: -9em;
    }
}